<template>
  <div class="app-container">
    <CrudTable
      ref="table"
      entity="dossiersTkReport"
      :columns="columns"
      :allow-actions="false"
      :on-row-dbl-click="onRowDblClick"
      selectable-columns
      @search="search"
    />
    <div id="toolbar" class="actions" style="padding:10px;">
      <button class="el-button base-button icon-only" data-cy="edit" @click="downloadReport()">Export</button>
    </div>
  </div>
</template>

<script>
import crudIndexMixin from '@/mixins/crud-index';
import CrudTable from '@/components/crud/CrudTable';
import request from '@/utils/request';
import { saveBlobAs } from '@/utils';
import { convertSearchToQueryParams } from '@/utils/form';

export default {
  components: {
    CrudTable
  },
  mixins: [crudIndexMixin],
  data() {
    return {
      query: '',
      columns: [
        { field: 'dossierID', width: 100, label: 'dossier.dossierID' },
        { field: 'requestDate', label: 'loan.requestDate', type: 'date', minWidth: '120' },
        { field: 'status', label: 'loan.status', minWidth: '120' },
        { field: 'toBoardDate', label: 'dossier.datumNaarBestuurTk', type: 'date', minWidth: '120' },
        { field: 'lastName', label: 'common.name', minWidth: '120' },
        { field: 'initials', label: 'common.initials', minWidth: '100' },
        { field: 'middleName', label: 'common.middleName', minWidth: '130' },
        { field: 'dateOfBirth', label: 'common.dateOfBirth', type: 'date', minWidth: '130' },
        { field: 'gender', label: 'common.gender', minWidth: '100' },
        { field: 'email', label: 'common.email', minWidth: '150' },
        { field: 'salaryScale', label: 'dossier.salaryscale', minWidth: '100' },
        { field: 'function', label: 'dossier.function', minWidth: '100' },
        { field: 'contractType', label: 'common.contractType', minWidth: '120' },
        { field: 'endDateContract', label: 'dossier.enddateContract', type: 'date', minWidth: '120' },
        { field: 'bcCode', label: 'dossier.bcCode', minWidth: '120' },
        { field: 'company', label: 'dossier.company', minWidth: '120' },
        { field: 'loanType', label: 'loan.loanType', minWidth: '120' },
        { field: 'amount', label: 'loan.amount', minWidth: '120' },
        { field: 'repaymentAmount', label: 'loan.repaymentAmount', minWidth: '120' },
        { field: 'terms', label: 'loan.terms', minWidth: '100' },
        { field: 'reason', label: 'loan.reason', minWidth: '120' }
      ]
    };
  },
  methods: {
    search(search) {
      this.query = convertSearchToQueryParams(search);
    },
    onRowDblClick(row) {
      this.$router.push({
        name: 'EditDossier',
        params: { id: row.dossierID, returnToPage: 'dossiersNaarBestuurTb' }
      });
      this.$emit('edit');
    },
    async downloadReport() {
      const columns = this.columns.filter(c => c.checked).map(c => c.field);

      await request
        .post(`report/DossierToBoardTkExport?${this.query}`, { columns: columns }, { responseType: 'blob' })
        .then(results => {
          saveBlobAs(results.data, 'Dossiers naar bestuur TK.xlsx');
        });
    }
  }
};
</script>

<style scoped></style>
